@import '00-settings/breakpoint';
@import '00-settings/grid';

#{$ezpz-namespace} {
	display: table;

	width: 100%;

	> .cell {
		display: table-cell;
	}

	&--vertical {
		display: block;

		.cell {
			display: block;
		}
	}
}
